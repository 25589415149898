import ApiService from './api.service'
const InspectorService = {
  Get(certificateNumber) {
    if (certificateNumber)
      return ApiService.get(
        `/Inspector/Get?certificateNumber=${certificateNumber}`
      );
    else return ApiService.get(`/Inspector/Get`);
  },
  GetAsSelectList(data) {
    return ApiService.post(`/Inspector/GetAsSelectList`, data);
  },
  GetSecurityInfo() {
    return ApiService.get(`/Inspector/GetSecurityInfo`);
  },
};
export default InspectorService