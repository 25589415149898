import ApiService from './api.service'
const MandatoryRequirementService = {
  GetList(data) {
    return ApiService.post(`/MandatoryRequirement/GetList`, data);
  },
  GetListLandingPage(data) {
    return ApiService.post(`/MandatoryRequirement/GetListLandingPage`, data);
  },
  GetSecurityInfo() {
    return ApiService.print(`/MandatoryRequirement/GetSecurityInfo`);
  },
};
export default MandatoryRequirementService